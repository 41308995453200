<template>
	<div id="feedback">
        <v-head></v-head>
        <div class="container">
            <div class="banner">
                <h1 class="title">用戶反饋</h1>
            </div>
            <div class="content">
                <div class="row">
                    <span class="hint">您的聯絡方式：</span>
                    <input type="text" class="connect" v-model="contact" placeholder="請輸入電郵、電話、WhatsApp">
                </div>
                <div class="col">
                    <span class="hint">輸入您的反饋內容</span>
                    <textarea cols="30" rows="5" v-model="msgTxt" placeholder="請輸入您的問題、意見或建議（10~500字以內）"></textarea>
                </div>
                <div class="btn-commit" @click="addFeedback"><div class="lds-ring" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '正在提交...' : '提交反饋'}}</div>
                <div class="connect-us">
                    <p>我們的聯絡方式：</p>
                    <p>熱綫電話： (852) 3590 4880</p>
                    <p>電郵地址： info@ectcm.com</p>
                    <p>Whatsapp： 93839432</p>
                    <p>地　　址： 香港數碼港道100號數碼港3座F區5樓 郵箱210號</p>
                </div>
            </div>
        </div>
        <v-foot></v-foot>
	</div>
</template>

<script>
    import vHead from '@/layouts/Head.vue'
	import vFoot from '@/layouts/Foot.vue'
	export default {
		components: {
			vHead,
			vFoot
        },
        data() {
            return {
                loading: false,
                msgTxt: null,
                contact: null,
            }
        },
        methods: {
            addFeedback: function(){
                if(this.loading) return
                var user = this.$store.getters.userInfo
                if(!user) {
                    this.$message.warning('未登錄')
                    return
                }
                // if(!this.contact || !this.contact.trim()) {
                //     this.$message.warning('請輸入電郵、電話、WhatsApp')
                //     return
                // }
                if(!this.msgTxt || !this.msgTxt.trim()) {
                    this.$message.warning('請輸入您的問題、意見或建議（10~500字以內）')
                    return
                }
                var params = {
                    clientid: user.clientid,
                    contact: this.contact,
                    msgTxt: this.msgTxt,
                }
                this.loading = true
                this.$store.dispatch('addFeedback', params)
                .then((rs)=>{
                    this.loading = false
                    this.$notification.success({
                        message: '提示',
                        description: '反饋提交成功！',
                    })
                    this.contact = null
                    this.msgTxt = null
                })
                .catch((error)=>{
                    this.loading = false
                    this.$message.warning(error || '操作失敗，請稍候重試')
                })
            }
        }
	};
</script>

<style lang="scss" scoped>
    #feedback {
        width: 100%;
    }

    .banner {
        width: 100%;
        height: 146px;
        background-image: url(../../../assets/imgs/pc/aboutus_bannel@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .banner > .title {
        font-size: 42px;
        font-weight: bold;
        color: #231F20;
        margin-top: 45px;
        line-height: 42px;
        position: relative;
    }

    .banner > .title::before {
        content: '';
        position: absolute;
        left: -210px;
        top: 21px;
        width: 180px;
        height: 1px;
        background-color: #98E1E7;
    }

    .banner > .title::after {
        content: '';
        position: absolute;
        right: -210px;
        top: 21px;
        width: 180px;
        height: 1px;
        transform: translateY(-50%);
        background-color: #98E1E7;
    }

    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        align-items: center;
        justify-content: flex-start;
    }

    .content {
        width: 1000px;
        margin: 36px auto 41px auto;
        display: flex;
        flex-direction: column;
    }

    .content > .row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 40px;
    }

    .content > .col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .content > .row > .hint {
        color: #636363;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
    }

    .content > .row > input {
        color: #231F20;
        font-size: 18px;
        font-weight: bold;
        height: 36px;
        line-height: 36px;
        text-align: left;
        background-color: #F5F6F7;
        padding-left: 17px;
        box-sizing: border-box;
        width: 499px;
    }

    .content > .col > .hint {
        display: inline-block;
        position: relative;
        color: #1B2122;
        font-size: 18px;
        font-weight: bold;
        line-height: 18px;
        padding-left: 23px;
        margin-bottom: 10px;
    }

    .content > .col > .hint::after {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 15px;
        height: 15px;
        background-image: url(../../../assets/imgs/pc/img_fankui@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .content > .col > textarea {
        padding: 16px 20px;
        background-color: #F5F6F7;
        width: 1000px;
        box-sizing: border-box;
        height: 138px;
        font-size: 18px;
        font-weight: bold;
        color: #231F20;
    }

    .content > .col > textarea::placeholder,
    .content > .row > input::placeholder {
        font-size: 16px;
        font-weight: 400;
        color: #969696;
    }

    .content > .btn-commit {
        width: 228px;
        height: 44px;
        border-radius: 10px;
        background-color: #2B99A6;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        user-select: none;
        margin-top: 67px;
        align-self: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .content > .btn-commit:active {
        opacity: 0.7;
    }

    .content > .connect-us {
        margin-top: 95px;
        display: flex;
        flex-direction: column;
    }

    .content > .connect-us > p {
        color: #636363;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
    }


    /** loading start */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
    }
    
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 4px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /** loading end */ 
</style>
